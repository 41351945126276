<template>
  <div class="sharing">
    <div class="buttons mb-0">
      <a
        :href="`https://www.facebook.com/share.php?u=${url}`"
        class="button is-dark mx-0 mb-0"
        aria-label="Share on Facebook"
        target="_blank"
      >
        <fa :icon="['fab', 'facebook-f']" />
      </a>
      <a
        :href="`https://www.twitter.com/share?url=${url}`"
        class="button is-dark mx-0 mb-0"
        aria-label="Share on Twitter"
        target="_blank"
      >
        <fa :icon="['fab', 'twitter']" />
      </a>
      <a
        :href="`https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}`"
        class="button is-dark mx-0 mb-0"
        aria-label="Share on LinkedIn"
        target="_blank"
      >
        <fa :icon="['fab', 'linkedin-in']" />
      </a>
    </div>
    <p class="subtitle is-size-6">
      SHARE
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      url: `${window.location.origin}${this.$route.path}`,
    };
  },
};
</script>
